import { Country } from "./countries";

export interface UserSettings {
  registrationStatus: string;

  account: {
    firstName: string;
    lastName: string;
    organisationType: string;
    organisationName: string;
    is_student: boolean;
    come_from: string;
    hubspotId: string;

    // For filling leters automatically
    shortName: string;
    tel: string;
    role: string;
  };

  preferences: {
    country: Country;
    books: string[];
    details: string;
  };
}

export const defaultUserSettings: UserSettings = {
  registrationStatus: "not-registered",

  account: {
    firstName: "",
    lastName: "",
    organisationType: "",
    organisationName: "",
    come_from: "",
    is_student: false,
    hubspotId: "",

    shortName: "",
    tel: "",
    role: "",
  },

  preferences: {
    country: Country.CH,
    books: [],
    details: "",
  },
};
