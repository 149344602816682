// Sharepoint folders for internal client data
export enum SharepointFolderName {
    // ALL lowercase! strings for directly using as index name
    // Lowercase is for normalization and using as part of indexe name
    Vaudoise = 'vaudoise',
    Schaeppi = 'schaeppi',
    OPES = 'opes',
    SIA = 'sia',
    Test = 'test',
    NoInternal = '',
}