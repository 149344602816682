import { LanguageOptions } from "./language-options";

export enum Country {
    CH = 'CH',
    DE = 'DE',
    FR = 'FR',
    US = 'US',
    BR = 'BR',
}

export function getAllCountries(): Country[] {
    return Object.values(Country);
}

// Helper to get the countries full name
export const getCountryFullName = (country: Country): string => {
    const map: Record<Country, string> = {
        [Country.CH]: 'Switzerland',
        [Country.DE]: 'Germany',
        [Country.FR]: 'France',
        [Country.US]: 'United States',
        [Country.BR]: 'Brazil',
    };
    return map[country];
}

// Helper to get langauge of fedeeral data
export const getFederalDataLanguage = (country: Country): LanguageOptions => {
    const map: Record<Country, LanguageOptions> = {
        [Country.CH]: LanguageOptions.German,
        [Country.DE]: LanguageOptions.German,
        [Country.FR]: LanguageOptions.French,
        [Country.US]: LanguageOptions.English,
        [Country.BR]: LanguageOptions.Portuguese,
    };
    return map[country];
}
