// The languages the backend supports
export enum LanguageOptions {
    German = "de",
    French = "fr",
    Italian = "it",
    English = "en",
    Portuguese = "pt",
    Unknown = "unknown"
}

// Helper to get full name of language
export const getLanguageFullName = (lang: LanguageOptions): string => {
    const map: Record<LanguageOptions, string> = {
        [LanguageOptions.German]: 'German',
        [LanguageOptions.French]: 'French',
        [LanguageOptions.Italian]: 'Italian',
        [LanguageOptions.English]: 'English',
        [LanguageOptions.Portuguese]: 'Portuguese',
        [LanguageOptions.Unknown]: 'Unknown',
    };
    return map[lang];
}
